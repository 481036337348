import isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Typo from "~/components/atoms/Typo";
import { TUnitType } from "~/entities/unitType";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import { formatRangeValue } from "~/utilities/helpers/formatRangeValue";
import processPrices from "~/utilities/helpers/processPrices";

export interface UnitTypeProps {
  unitType: TUnitType;
  displaySqft?: boolean;
}

const UnitType: React.FC<UnitTypeProps> = ({
  unitType,
  displaySqft = true,
}) => {
  const { t } = useTranslation();
  const [areaSize, setAreaSize] = useState<string>("");

  useEffect(() => {
    const newAreaSize = formatRangeValue(
      unitType?.minArea ?? 0,
      unitType?.maxArea ?? 0
    );
    setAreaSize(newAreaSize);
  }, [unitType]);

  // Memoize the price to avoid unnecessary updates
  const price = useMemo(() => {
    if (isEmpty(unitType)) {
      return "enquire_for_price";
    }
    return processPrices(
      unitType?.totalUnits,
      unitType?.availableUnits,
      unitType?.minPrice,
      unitType?.maxPrice
    );
  }, [unitType]);

  // Memoize the price to avoid unnecessary updates
  const pricePerSqft = useMemo(() => {
    if (isEmpty(unitType)) {
      return "enquire_for_price";
    }
    return processPrices(
      unitType?.totalUnits,
      unitType?.availableUnits,
      unitType?.minPSF,
      unitType?.maxPSF
    );
  }, [unitType]);

  if (!unitType) return null;

  return (
    <>
      <div className="flex w-full cursor-pointer items-center justify-between gap-x-1">
        <div className="flex flex-wrap items-center gap-x-3 gap-y-1">
          <Typo
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.SUB_TITLE_16}
            color={ETypoColor.CARD}
            className="font-bold"
          >
            {unitType.title}
          </Typo>
          {displaySqft && (
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.BODY_TITLE_14}
              color={ETypoColor.CARD}
              className="opacity-50"
            >
              {areaSize} {areaSize !== "" && t("project_card.sqft")}
            </Typo>
          )}
        </div>
        <div className="flex flex-row-reverse flex-wrap items-center justify-start gap-x-1 gap-y-1">
          <Typo
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.SUB_TITLE_16}
            color={ETypoColor.CARD}
          >
            {t(price)}
          </Typo>
          {pricePerSqft !== "sold_out" && displaySqft && (
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.BODY_TITLE_14}
              color={ETypoColor.CARD}
              className="opacity-50"
            >
              {`${t(pricePerSqft)} ${t("per_sqft")}`}
            </Typo>
          )}
        </div>
      </div>
    </>
  );
};

export default UnitType;
