import React from "react";

import { cn } from "~/utilities/cn";

interface IconChevronRightProps {
  className?: string; // Optional className to pass Tailwind CSS styles
}

const IconChevronRight: React.FC<IconChevronRightProps> = ({ className }) => {
  return (
    <svg
      className={cn(
        "h-3 w-3 text-color", // Default styles
        className // Apply any additional className passed via props
      )}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 8 14"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 13l5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
      />
    </svg>
  );
};

export default IconChevronRight;
